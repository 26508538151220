.refresh-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1f25 0%, #2b3137 100%);
  padding: 20px;
}

.reset-button {
  background: rgba(30, 144, 255, 0.1);
  border: 2px solid #1e90ff;
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 240px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(30, 144, 255, 0.2);
}

.reset-button:hover:not(:disabled) {
  background: rgba(30, 144, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 0 30px rgba(30, 144, 255, 0.3);
}

.reset-button:disabled {
  opacity: 1;
  cursor: default;
}

.reset-button.resetting {
  background: rgba(30, 144, 255, 0.15);
  animation: pulse 2s infinite;
}

.reset-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(30, 144, 255, 0.3);
  border-top: 3px solid #1e90ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background: #1e90ff;
  width: 0%;
  transition: width 0.5s ease;
}

.status-text {
  font-size: 14px;
  color: #1e90ff;
  margin-top: 12px;
  text-align: center;
  opacity: 0.8;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { box-shadow: 0 0 20px rgba(30, 144, 255, 0.2); }
  50% { box-shadow: 0 0 30px rgba(30, 144, 255, 0.4); }
  100% { box-shadow: 0 0 20px rgba(30, 144, 255, 0.2); }
}

.upgrade-sequence {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.upgrade-text {
  font-size: 24px;
  color: #1e90ff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  text-shadow: 0 0 10px rgba(30, 144, 255, 0.5);
}

.step-text {
  color: #fff;
  font-size: 16px;
  opacity: 0.8;
  margin: 4px 0;
} 